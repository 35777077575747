@import "../core/navbar";

.navbar{
  .navbar-minimize{
    padding: 3px 0 0 15px;
  }

  &.navbar-transparent{
    padding-top: 10px;
  }

  &.fixed-top{
    position: fixed !important;
  }
  .collapse{
    .navbar-nav{
      .nav-item .nav-link{
        position: relative;
        padding: 10px 15px;
        font-weight: $font-weight-default;
        font-size: $mdb-btn-font-size-base;
        text-transform: uppercase;
        border-radius: $border-radius-base;
        line-height: 20px;
        margin-left: 5px;
        color: inherit;

          &:not(.btn-just-icon) .fa{
              position: relative;
              top: 2px;
              margin-top: -4px;
              margin-right: 4px;
          }

          & .material-icons,
          & .fa{
              font-size: $font-size-lg;
              max-width: 24px;
              margin-top: -1.1em;
          }

          &:not(.btn) .material-icons{
              margin-top: -3px;
              top: 0px;
              position: relative;
              margin-right: 3px;
          }
          .notification{
            top: 0px;
          }
        }
    }
  }
}

.off-canvas-sidebar{
  .navbar{
    .navbar-collapse{
      .navbar-nav .nav-item{
        .nav-link{
          padding-top: 15px;
          padding-bottom: 15px;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          border-radius: 3px;
          color: $white-color;
          margin: 0 15px;

          &:hover{
            background: rgba(200, 200, 200, 0.2);
          }
        }
      }
    }
  }
}
