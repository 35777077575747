/* You can add global styles to this file, and also import other style files */
body {
  background-color: #ffffff;
  font-family: 'Helvetica' !important;
  .wrapper {
    width: 100%;
    margin: auto;
  }

  .page-content {
    margin-top: 110px;
    margin-bottom: 110px;
  }
}

.videoPlayerModal .mat-dialog-container {
  background: #000;
}

.miniSiteModal {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
    align-self: center;
    align-content: center;
  }
}

.home {
  .navbar {
    .container-fluid {
      border-bottom: 0 solid #ffffff !important;
    }
  }
}
.breadcrumb .breadcrumb-item a {
  font-family: 'Helvetica' !important;
}
